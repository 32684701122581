<template>
    <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="500px">
      <v-card v-if="keybutton === false">
        <v-card-title>{{$t('admin.headerCheck')}}</v-card-title>
        <v-divider></v-divider>
        <br/>
        <v-card-text v-if="checkset=== true && checkmode=== 'd'">       
          <span style="font-size:medium">{{$t('admin.opencheckdepartment')}}</span>
        </v-card-text>
        <v-card-text v-else-if="checkset=== false && checkmode=== 'd'">
          <span style="font-size:medium">{{$t('admin.closecheckdepartment')}}</span>
          <br/><br/>
          <span style="color:red">{{$t('admin.confirmclosedepartment')}}</span>
        </v-card-text>

        <v-card-text v-else-if="checkset=== true && checkmode=== 'r'">       
          <span style="font-size:medium">{{$t('admin.opencheckrole')}}</span>
        </v-card-text>
        <v-card-text v-else-if="checkset=== false && checkmode=== 'r'">
          <span style="font-size:medium">{{$t('admin.closecheckrole')}}</span>
          <br/><br/>
          <span style="color:red">{{$t('admin.confirmcloserole')}}</span>
        </v-card-text>
        
        <v-card-text v-else-if="checkset=== true && checkmode=== 'e'">
          <span style="font-size:medium">{{$t('admin.opencheckemployee')}}</span>         
        </v-card-text>
        <v-card-text v-else-if="checkset=== false && checkmode=== 'e'">
          <span style="font-size:medium">{{$t('admin.closecheckemployee')}}</span>
          <br/><br/>
          <span style="color:red">{{$t('admin.confirmcloseemployee')}}</span>
        </v-card-text>

         <v-card-text v-else-if="checkset=== true && checkmode=== 'bu'">
          <span style="font-size:medium">{{$t('admin.opencheckbusiness')}}</span>         
        </v-card-text>
        <v-card-text v-else-if="checkset=== false && checkmode=== 'bu'">
          <span style="font-size:medium">{{$t('admin.closecheckbusiness')}}</span>
          <br/><br/>
          <span style="color:red">{{$t('admin.confirmclosebusiness')}}</span>
        </v-card-text>

        <!-- <v-card-text v-else-if="checkset=== true && checkmode=== 'clone'">
          <span style="font-size:medium">หากเปิดการตั้งค่าสิทธิ์เฉพาะโฟลเดอร์นี้ จะทำให้ไฟล์/โฟลเดอร์ที่อยู่ภายใต้ไม่ตั้งสิทธิ์ตามไปด้วย</span>         
        </v-card-text> -->
        <v-card-text v-else-if="checkset === true && checkmode=== 'clone'">
          <span style="font-size:medium">{{$t('admin.confirmclonepermission')}}</span>
          <!-- <br/><br/>
          <span style="color:red"></span> -->
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="red" outlined  @click="close()">{{$t('admin.close')}}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme"  @click="submit()" >{{$t('admin.submit')}}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="keybutton === true">
        <v-card-title>{{$t('admin.confrimsetting')}} {{file.file_name}}</v-card-title>
        <v-divider></v-divider>
        <br/>
        <v-card-text>
          <span style="font-size:medium">{{$t('admin.wanttoset')}}</span>
          <br/><br/>
          <!-- <span style="color:red">{{$t('admin.textset')}}</span> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="red" outlined  @click="closebutton()">{{$t('admin.close')}}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme"  @click="submitbutton()" >{{$t('admin.submit')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
  //    mixins: [validationMixin],
  //  validations: {
  //     filename: { required },
  //   },
    props: ['show','keyset','keymode','keybutton','file'],
    data: function() {
      return {
        createprogress:false,
        test:1,
        checkset:'',
        checkmode:true,
      }

    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                  //if(this.test === 1){
                   //console.log(this.show);
                   console.log(this.keyset);
                   this.checkset = this.keyset;
                   this.checkmode = this.keymode;
                   console.log(this.checkset,this.checkmode,this.keybutton);
                   
                  //}
                 //this.test = 0;
                }
                return this.show
            },
            set (value) {
                if (!value) {
                  this.$emit('closechangename')
                }
            }
        },},
      
       methods: {
         submit(){
           this.$emit('confrimsetting')
           this.$emit('close');
           
         },
         close(){
           console.log("test");
           this.test = 1;
           this.$emit('notsetting')
           this.$emit('close');
           
         },
          submitbutton(){
           this.$emit('confrimsettingbutton')
           this.$emit('close');
           
         },
         closebutton(){
           console.log("test");
           this.test = 1;
           this.$emit('notsettingbutton')
           this.$emit('close');
           
         }
           
       }
    
}
</script>
<style>

</style>